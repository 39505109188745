.impressum-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it stays on top */
}

.impressum-popup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px; /* Adjusted max-width for better fit */
  max-height: 70vh; /* Reduced max-height */
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left; /* Text align left for readability */
  position: relative; /* To position the close button */
}

.impressum-popup h3 {
  margin-top: 0;
  color: #283044;
  font-size: 1.5rem;
}

.impressum-popup p {
  margin: 10px 0;
  color: #283044;
  font-size: 0.9rem; /* Slightly reduced font size */
  line-height: 1.5;
}

.impressum-popup button {
  background: #283044;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  position: absolute; /* Fix the button position */
  top: 10px;
  right: 10px;
}

.impressum-popup button:hover {
  background: #1e2a36;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .impressum-popup {
      width: 95%;
      max-height: 60vh; /* Further reduced for mobile */
      padding: 15px; /* Reduced padding */
  }

  .impressum-popup h3 {
      font-size: 1.25rem; /* Smaller font for smaller screens */
  }

  .impressum-popup p {
      font-size: 0.85rem; /* Adjusted font size for mobile */
  }

  .impressum-popup button {
      padding: 8px 16px;
      font-size: 0.9rem;
  }
}
