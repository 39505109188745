footer {
  background-color: #283044;
  color: #ffffff;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
}

.footer-container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-column {
  flex: 1;
  min-width: 250px;
  margin: 20px;
  text-align: center; /* Ensure text and button are centered */
}

.footer-column h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #78a1bb;
}

.footer-column p,
.footer-column a {
  color: #ebf5ee;
  font-size: 1rem;
  margin-bottom: 10px;
  text-decoration: none;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-bottom {
  margin-top: 20px;
  border-top: 1px solid #ebf5ee;
  padding-top: 10px;
  color: #ebf5ee;
}

.about-button {
  background: #78a1bb;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  display: inline-block; /* Ensure the button does not stretch full width */
}

.about-button:hover {
  background: #5a8aa1;
}
