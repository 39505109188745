body {
  font-family: 'Courier New', Courier, monospace;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #78a1bb;
  background-image: 
    linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 40px 40px;
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
}

.App-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin);
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .App-header {
    font-size: calc(6px + 2vmin);
    padding: 10px;
  }
}

.back-button {
  color: white;
  background-color: var(--frame-color);
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 30px;
  border: 2px solid var(--frame-color);
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  min-width: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 10px; /* Setzt den Abstand zum oberen Rand */
  left: 20px; /* Fügt einen Abstand von 20px vom linken Rand hinzu */
  z-index: 100;
}

/* Entfernt das Pfeilsymbol (falls es ein Icon oder Pseudoelement ist) */
.back-button::before {
  content: none; /* Verhindert die Anzeige von Pseudoelementen wie Icons */
}

.back-button:hover {
  background-color: white;
  color: var(--frame-color);
  border: 2px solid var(--frame-color);
  transform: scale(1.05);
}



/* Fade-in Effekt */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out; /* Erhöhte Dauer auf 1.5s */
}

.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}
