:root {
  --primary-color: #00c8ff;
  --secondary-color: #4fa3b8;
  --border-color: #ddd;
  --text-color: #666;
  --box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  --frame-color: #283044;
}

.FaceIDSection {
  padding: 20px;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  text-align: center;
}

.section-title {
  font-size: 2.5em;
  color: #283044;
  text-decoration: underline;
  text-decoration-color: #61dafb;
  text-decoration-thickness: 3px;
  margin-bottom: 20px;
}

.text-box {
  padding: 2vw;
  border: 3px solid var(--frame-color);
  border-radius: 15px;
  background: linear-gradient(135deg, #ebf5ee 0%, #c2d3d9 100%);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  margin: 0 auto 3vh auto;
  text-align: center;
}

.text-box p {
  margin: 0 auto 3vh auto;
  font-size: 1.2rem;
  font-weight: 600;
  color: #283044;
  line-height: 1.6;
  font-family: 'Courier New', Courier, monospace;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 2vw;
}

.btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #61dafb;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #4fa3b8;
}

.button-icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.pdf-icon {
  font-size: 2.5rem; /* Größerer Icon für PDF */
  margin-bottom: 10px; /* Abstand zwischen Icon und Text */
}

.btn-text {
  font-size: 1rem; /* Textgröße */
}

.iframe-container-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 2vw;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.iframe-container {
  background-color: #fbfafa;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  width: 100%;
  max-width: 50%;
  aspect-ratio: 225 / 240;
  margin: 0 auto;
  box-sizing: border-box;
}

.iframe-content {
  width: 100%;
  height: 100%;
  border: none;
}

.pdf-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 80%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: hidden;
}

.pdf-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1100;
}

.large-icon {
  text-align: center;
  margin-bottom: 20px;
}
